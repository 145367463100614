.carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    margin: auto;
    padding: 0 2rem;
    border-radius: 10px;
}

.carousel-content {
    display: flex; 
    justify-content: center; 
    padding: 2rem;
    background: transparent;
    color: white;
}

.desc {
    padding: 15px 20px;
    width: 100%;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box; 
}

.testimonial-item {
    width: 400px;
    border-radius: 14px;
    background: #050331;
    margin: 1rem;
    padding: 1rem;
    height: 500px;

}

.carousel-content p {
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
    line-height: 2rem;
}

.carousel-content img {
    width: 6rem;
    border-radius: 50%;
    margin: 0 1rem;
}

.image-caption {
    font-style: italic;
    font-weight: bolder;
    font-size: 1rem;
    margin-top: 0.5rem;
}

.carousel-control {
    background: transparent;
    border: 1px solid #0800ff;
    color: white;
    padding: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 1rem;
    border-radius: 5px;
}

.carousel-control:hover {
    opacity: 0.8;
}

.test-desc {
    text-align: justify;
}

.im_na {
    display: flex;
    flex-wrap: wrap;
}

.lli {
    list-style: none;
}

@media (max-width:786px) {
    .desc {
        padding: 0;
        margin: 10px;
        flex-direction: column;
    }
    .carousel-content {
        margin: 0;
        padding: 0;
        flex-direction: column;
    }
    .testimonial-item {
        width: 100%;
        padding: 10px;
        margin: 10px;
        height: auto;
    }
    .carousel-content img {
        width: 4rem;
    }
    .image-caption {
        font-size: 0.9rem;
    }
    .test-desc {
        font-size: 1rem;
    }
}
