.internship-container {
    background: #030117;
    text-align: center;
    color: white;
    padding-bottom: 5rem;
    z-index: -1;
}

/* .internship-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 2px solid rgba(0, 21, 255, 0.5);
}
.intern-enroll-btn {
    width: 200px;
    font-size: 20px;
    background: transparent;
    font-weight: bolder;
    color: rgb(255, 255, 255);
    margin: 2rem 0;
    padding: 0.5rem;
    border: 2px solid #0d00ff;
    border-radius: 12px;
}

.intern-login-btn {
    width: 200px;
    font-size: 20px;
    background: transparent;
    font-weight: bolder;
    color: rgb(255, 255, 255);
    margin: 2rem 0;
    padding: 0.5rem;
    border: 2px solid #0d00ff;
    border-radius: 12px;
}

.intern-enroll-btn:hover, .intern-login-btn:hover {
    color: #1100ff;
    box-shadow: 0 0 16px rgba(0, 21, 255, 0.9);
}

.internship-title {
    font-weight: bolder;
    font-size: 40px;
    color: #ffffff;
} */

.internship-cont {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin-bottom: 5rem;
}

.internship-card {
    user-select: none;
    width: 20%;
    text-align: start;
    background: rgb(255, 255, 255);
    color: var(--body_background);
    font-weight: bold;
    border: 1px solid #020116;
    border-radius: 12px;
    margin: 1rem;
    padding: 1rem;
}

.internship-card:hover {
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.9);
}

.intern-title {
    text-align: center;
}

.method-cont {
    background: #010420;
    border: 2px solid #0015ff;
    border-radius: 4px;
    padding: 1rem;
}

.method-sub-cont {
    display: flex;
    flex-wrap: wrap;
}

.method-cont1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 50%;
}

.method-img {
    width: 340px;
    height: 200px;
    border-radius: 10px;
}

.method-title1 {
    margin: 20px;
    text-align: justify;
    width: 300px;
    font-size: 20px;
}

.method-cont2 {
    width: 50%;
}

.method-card1 {
    width: fit-content;
    user-select: none;
    background: #010117;
    text-align: center;
    border-radius: 10px;
    margin: 1rem 2rem;
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
}

.method-card1:hover {
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.9);
}

.intern-title {
    color: #001eff;
}

.intern-desc {
    list-style: none;
    text-align: justify;
}

.intern-desc1 {
    text-align: justify;
    font-size: 25px;
    margin: 3rem;
}

.intern-cont1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.internship-card2 {
    user-select: none;
    background: var(--sub_header);
    width: 28%;
    text-align: center;
    border: 1px solid var(--sub_header);
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
}

.internship-card2:hover {
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.9);
}

.intern-title1 {
    color: #ffffff;
}

.intern-title2 {
    text-align: justify;
    font-size: 30px;
    font-weight: bolder;
    color: #00eaff;
}

.take-desc {
    text-align: justify;
    font-weight: bolder;
    color: #ffffff;
    font-size: 20px;
}

.takeaway {
    margin: 10rem 0;
}



.intern-link {
    text-decoration: none;
}


@media (max-width:786px) {
    /* .internship-header {
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin: 10px;
        padding: 0;
        gap: 0;
    }
    .internship-title {
        font-size: 30px;
    } */
    .internship-card {
        width: 100%;
        margin: 10px;
        padding: 10px;
    }
    .internship-card1, .internship-card2 {
        width: 100%;
    }
    .method-cont, .intern-desc1 {
        margin: 10px;
        padding: 0;
    }
    .intern-count1 {
        flex-direction: column;
    }
    .intern-desc1 {
        font-size: 20px;
    }
    .method-cont {
        width: fit-content;
    }
    .method-sub-cont, .method-cont1 {
        width: 100%;
        text-align: center;
        justify-content: center;
        flex-direction: column;
    }
    .method-cont2 {
        margin: 0;
        width: 100%;
        
    }
    .method-card1 {
        margin: 10px;
        width: fit-content;
    }
    .method-img {
        margin: 10px;
        width: 350px;
        border-radius: 10px;
    }
}