/* Login.css */

.login-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  background-color: var(--body_background);
  position: relative;
  height: 90vh;
  overflow: hidden;
  border-radius: 20px;
}

.login-gradient-text-h1 {
  height: 100%;
  width: 100%;
  max-height: 600px;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--body_background);
  border: 3px solid var(--link_color);
  border-radius: 18px;
  box-shadow: 0 0 16px rgba(0, 217, 255, 0.9);
  transition: transform 0.3s ease-in-out;
  top: 0;
  left: 0;
  position: relative; 
  z-index: 1; 
  font-weight: bold;
  background: linear-gradient(45deg, #1d09ff, #d03c34, #49f85a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  user-select: none;
}

.login-gradient-text-h1:hover {
  transform: scale(1.02);
}

.login-title {
  text-align: center;
  font-size: 2rem;
  padding-bottom: 0.5rem;
  color: var(--body_color);
  border-bottom: 2px solid var(--link_color);
}  

.alert-danger {
  margin-top: 1rem;
  font-size: 1rem;
  padding: 1rem;
  border: 1px solid #f5c6cb;
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 4px;
}

.form-label {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  color: var(--link_color);
  display: block;
  margin: 1rem 7rem;
}

.login-input {
  font-family: consolas;
  width: 70%;
  background-color: var(--body_background);
  color: var(--body_color);
  padding: 1rem;
  border: 2px solid var(--link_color);
  border-radius: 5px;
  font-size: 1rem;
}

.login-input:focus {
  border-color: var(--link_color);
  outline: none;
}

.login-button {
  width: 40%;
  font-size: 1.25rem;
  font-weight: bolder;
  margin: 2rem 0 2rem 0;
  padding: 0.5rem;
  border: 3px solid var(--link_color);
  border-radius: 14px;
  background-color: var(--body_background);
  color: #fff;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  color: var(--link_color);
  background-color: var(--body_background);
  border: 2px solid var(--body_color);
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.1);
}

.login-spinner {
  margin-right: 0.5rem;
}

.login-fluid-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
/* Input container with relative positioning */
.password-container {
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* Styling for the password input */
.password-container input {
  width: 65%;
  padding-right: 50px; /* To ensure space for the button */
}

/* Position the show/hide button inside the input field */
.password-toggle-btn {
  position: absolute;
  right: 120px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--link_color);
}

.password-toggle-btn:hover {
  color: var(--link_color);
}

.link-l {
  color: var(--link_color);
  text-decoration: none;
}

.link-l:hover {
  color: var(--link_color);
  text-decoration: none;
}



/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .password-toggle-btn {
      right: 60px;
  }
  .password-container input {
      width: 62%;
  }
  .form-label {
    margin: 1rem 3rem;
  }
  .login-gradient-text-h1 {
    margin: 1rem;
  }
}

/* @media (max-width: 480px) {
  .login-gradient-text-h1 {
      font-size: 1.5rem;
  }
  .login-title {
      font-size: 1.75rem;
  }
  .login-input {
      font-size: 0.9rem;
      padding: 0.75rem;
  }
  .login-button {
      max-width: 220px;
      padding: 0.5rem;
  }
  .form-label {
      margin: 0.75rem;
  }
} */
