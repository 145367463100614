@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
  }

.about-container {
    background: transparent;
    margin-bottom: 10rem;
    padding-top: 10rem;
    animation: fadeInScale 0.8s forwards;
}

.about-title {
    text-align: center;
    font-size: 50px;
    margin: 0 0 6rem;
}

.abt-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.abt-left {
    width: 30%;
    margin: 1rem 10rem;
    text-align: justify;
}

.abt-right {
    width: 50%;
}

.about-desc {
    font-size: 20px;
}

.abt-vid {
    width: 700px;
}

@media (max-width:786px) {
    .about-title, .about-desc {
        margin:1rem;
    }
    .abt-cont {
        flex-direction: column;
    }
    .abt-left {
        text-align: justify;
        margin: 0;
        width: 100%;
    }
    .abt-right {
        text-align: center;
        margin: 0;
        width: 100%;
    }
    .abt-vid {
        width: 400px;
    }
    
}