.home-container-main {
    position: relative; 
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    color: white;
    margin-bottom: 10rem;
    height: 90vh;
    overflow: hidden; 
    padding-top: 1rem;
}

.home-container-main::before {
    content: '';
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../Assets/Images/ai-1.jpg) no-repeat center center; 
    background-size: cover; 
    filter: blur(15px); 
    z-index: -1; 
}


.left-side {
    width: 80%;
    margin-top: 2rem;
    font-family: sans-serif;
    text-align: left;
    z-index: 1;
}

.comp-title {
    height: 60px;
    color: #002aff;
    user-select: none;
    font-size: 90px;
    font-weight: bolder;
    opacity: 0; 
    transform: scale(0.5); 
    animation: fadeInScale 0.8s forwards; 
}

@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}


.comp-title1 {
    color: white;
    user-select: none;
    font-size: 60px;
    font-weight: bolder;
    font-style: italic;
    animation: fadeInScale 1s forwards;
    height: 60px;
}

.typed {
    color: aqua;
    user-select: none;
    font-size: 60px;
    animation: fadeInScale 1.5s forwards;
}

.typed1 {
    color: rgb(0, 255, 94);
    user-select: none;
    font-size: 40px;
    animation: fadeInScale 1.5s forwards;
}

.p1 {
    color: white;
    font-size: 20px;
    font-weight: bold;
    animation: fadeInScale 2s forwards;
}

.opt1 {
    background: linear-gradient(45deg, #d03c34, #49f85a, #09d2ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    user-select: none;    
    font-style: italic;
    font-size: 40px;
    transition: 2s;
    transform: rotateY(360deg);
    animation: fadeInScale 2.5s forwards;
}

.internship-btn{
    font-size: 30px;
    font-weight: bold;
    padding: 1rem;
    margin: 1rem 0;
    color: rgb(255, 255, 255);
    background: #0000ff;
    border: 2px solid rgb(0, 13, 255);
    border-radius: 10px;
    cursor: pointer;
}

.internship-btn:hover {
    background: #0a0ac3;
    border: 2px solid rgb(12, 21, 202);
    transform: scale(1.02);
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
}

.demo-btn{
    width: 20rem;
    font-size: 30px;
    font-weight: bold;
    padding: 1rem;
    margin: 1rem 2rem;
    color: rgb(255, 255, 255);
    background: #0000ff;
    border: 2px solid rgb(0, 13, 255);
    border-radius: 10px;
    cursor: pointer;
}

.demo-btn:hover {
    background: #0a0ac3;
    border: 2px solid rgb(12, 21, 202);
    transform: scale(1.02);
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
}

.m-v {
    text-align: center;
    justify-content: center;
}

.vid {
    width: 1000px;
    border: 2px solid rgb(28, 2, 43);
    box-shadow: 0 0 16px rgba(61, 58, 58, 0.9);
    border-radius: 4px;
    margin-top: 10rem;
}

@media (max-width:786px) {
    .home-container {
        text-align: center;
        justify-content: center;
        flex-direction: column;
        /* margin: 10px; */
        padding: 0;
    }
    .left-side {
        text-align: center;
        width: 100%;
    }
    .comp-title {
        font-size: 3rem;
    }
    .comp-title1, .typed, .typed1 {
        font-size: 2rem;
        height: 130px;
    }
    .p1, .opt {
        font-size: 1rem;
    }
    .vid {
        width: 400px;
    }
    .internship-btn {
        font-size: 1.5rem;
    }
    .demo-btn {
        font-size: 1.5rem;
        width: 10rem;
    }
}