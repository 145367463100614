.fluid-container {
    position: relative;
    height: 90vh;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    margin-top: 5px;
  }
  
  .fluid-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .gradient-text-h1 {
    top: 0;
    left: 0;
    position: relative; 
    z-index: 1; 
    margin: 25vh 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 70px;
    font-weight: bold;
    background: linear-gradient(45deg, #1d09ff, #d03c34, #49f85a);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    user-select: none;
  }

  /* Mobile Responsive Styles */
@media (max-width: 768px) {
  .gradient-text-h1 {
    font-size: 30px;
  }
}
  
@media (max-width: 480px) {
  .gradient-text-h1 {
    font-size: 30px;
  }
}