.header {
  user-select: none;
  max-width: calc(100% - 2rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  text-align: center;
  padding: 0 2.5rem;
  position: sticky;
  top: 0;
  z-index: 30;
  backdrop-filter: blur(50px);
  background-color: transparent;
  border-bottom: 2px solid var(--body_color); 
}

.head-content {
  color: var(--body_color);
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.App-logo {
  width: 200px;
  margin-right: 30px;
  border-radius: 5rem;
}

.title {
  font-style: italic;
  color: var(--body_color);
  text-align: center;
}

.mode {
  gap: 1rem;
  justify-content: space-between;
  text-align: center;
  display: flex;
  margin-left: auto;
}

.hamburger {
  display: none;
}

.link {
  text-decoration: none;
  color: var(--link_color);
}

.link:hover {
  text-decoration: none;
  color: var(--link_color);
}

.nav-content {
  color: var(--body_color);
  max-width: 1300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin: 0 auto;
}

.nav-content ul {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  list-style: none;
}

.nav-content li {
  margin: 0 1.5rem;
}

.menu-item {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 800;
  color: var(--body_color);
  position: relative;
  cursor: pointer;
}

.menu-item::before {
  content: " ";
  width: 2rem;
  height: 0.2rem;
  background: linear-gradient(90deg, cyan 0%, #7e61e7 100%);
  border-radius: 0.5rem;
  position: absolute;
  bottom: -0.6rem;
  opacity: 0;
  transform: translateX(-1rem);
  transition: all 0.3s ease;
}

.menu-item:hover::before {
  width: 100%;
  transform: translateX(0);
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .header {
    max-width: calc(100% - 3rem);
    padding: 0 1.5rem;
    margin: 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    font-size: 2rem;
    color: var(--body_color);
    position: absolute;
    right: 1rem; /* Position to the right end */
  }

  .nav-content {
    display: none;
  }

  .mode.open .nav-content {
    display: block;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: var(--body_background);
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-content ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .nav-content li {
    margin: 0.5rem 0;
  }
}
/* Header.css */

/* Basic styling for the logout button */
.logout-button {
  background-color: #dc3545; /* Red background for logout */
  color: #fff; /* White text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  padding: 0.5rem 1rem; /* Padding around the text */
  font-size: 1rem; /* Font size */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for background color and transform */
  outline: none; /* Remove outline */
}

.logout-button:hover {
  background-color: #c82333; /* Darker red when hovered */
  transform: scale(1.05); /* Slightly enlarge the button */
}

.logout-button:focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); /* Add shadow to indicate focus */
}

.logout-button:active {
  background-color: #bd2130; /* Even darker red when clicked */
  transform: scale(0.98); /* Slightly shrink the button on click */
}
