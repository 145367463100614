.contact-container {
    background: transparent;
    text-align: center;
    margin-bottom: 20rem;
    padding-top: 5rem;
    animation: fadeInScale 0.8s forwards;
}

.contact-title {
    font-size: 50px;
    color: rgb(157, 181, 222);
}

.contact-sub {
    font-size: 20px;
    margin: 2rem;
}

.contact-btn {
    width: fit-content;
    color: #ffffff;
    background: transparent;
    border: 2px solid #081fcf;
    border-radius: 3px;
    padding: 1rem 2rem;
    font-weight: bolder;
    cursor: pointer;
}

.contact-btn:hover {
    transform: scale(1.03);
}