@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
  }
  
.service-container {
    background: transparent;
    text-align: center;
    margin-bottom: 10rem;
    padding-top: 5rem;
    animation: fadeInScale 0.8s forwards;

}

.service-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 5rem;
}

.service-title {
    font-size: 50px;
    margin: 2rem 10rem;
}

.service-box {
    width: 30%;
    height: 370px;
    text-align: center;
    background: var(--body_color);
    color: var(--body_background);
    border: 1px solid #020420;
    border-radius: 10px;
    margin: 0.75rem;
    padding: 0 0.65rem;
    transition: all 0.3s;
}

.service-box:hover {
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.9);
    border: 1px solid #0800ff;
    /* transform: scale(1.03); */
}

.service-sub {
    color: #000dff;
    font-size: 35px;
    font-weight: bolder;
}

.service-desc {
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
    text-align: justify;
    margin: 1rem;
    font-weight: bolder;
}

@media (max-width:786px) {
    .service-container {
        text-align: center;
        justify-content: center;
        flex-direction: column;
    }
    .service-cont {
        margin: 0;
        padding: 0;
    }
    .service-box {
        margin: 10px;
        padding: 20px;
        width: 100%;
        height: 370px;
    }
    .service-title {
        margin: 1rem;
    }
    .service-desc {
        margin: 1rem;
    }
    .service-sub {
        font-size: 30px;
    }
}