@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.home2-container {
    display: flex;
    flex-wrap: wrap;
    background: transparent;
    text-align: center;
    justify-content: center;
    margin-bottom: 10rem;
    z-index: 1;
    animation: fadeInScale 0.8s forwards;
}

.h2-left-side {
    background: transparent;
    width: 100%;
    text-align: center;
    font-family: consolas;
    font-weight: bold;
    /* padding: 0 10rem; */
    z-index: 1;
}

.abt {
    text-align: center;
    background: var(--sub_header);
    margin: 0 10rem;
}

.m-title {
    color: #007bff;
    margin: 0 2rem;
    font-size: 50px;
}

.m-des {
    margin: 0 2rem;
    text-align: center;
} 

.semi-title {
    font-size: 40px;
}

.main-box {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.box {
    position: relative;
    width: 400px;
    background: #000000;
    margin: 1rem;
    cursor: pointer;
    border: 2px solid rgb(7, 16, 135);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box:hover {
    transform: scale(1.03);
}

.box-img {
    width: 400px;
    height: 250px;
}

.h2-btn{
    font-size: 15px;
    font-weight: bold;
    padding: 0.5rem;
    margin: 1rem 0;
    color: cyan;
    background: transparent;
    border: 2px solid rgb(20, 1, 29);
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}

.h2-btn:hover {
    box-shadow: 0 0 16px rgb(0, 238, 255);
}

.p-desc {
    margin: 0.75rem 1rem;
    text-align: justify;
}

@media (max-width:786px) {
    .home2-container {
        flex-direction: column;
    }
    .h2-left-side, .h2-right-side {
        width: 100%;
    }
    .m-title, .m-des, .abt {
        margin: 0;
    }
    .h2-left-side {
        padding: 0;
    }
    .m-des {
        margin: 20px;
        text-align: justify;
    }
}