.h-m {
    text-align: center;
}

.home {
    width: auto;
    color: var(--body_color);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* padding: 1.5rem; */

}
.home-items {
    width: fit-content;
    color: var(--body_color);
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.home-anchor {
    background-color: var(--body_background);
    color: var(--body_color);
    border: 2px solid var(--body_color);
    border-radius: 12px;
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.9);
    margin: 2rem 2rem 2rem 5rem;
    padding: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    height: 25rem;
    width: 100%;
    max-width: 350px;
}

.home-anchor:hover {
    transform: scale(1.05);
}

.c-img {
    border-radius: 1rem;
    width: 25rem;
    height: 15rem;
    max-width: 100%;
}

.description {
    font-size: 1.05rem;
    color: var(--body_color);
    text-decoration: none;
    justify-content: center;
    font-family: consolas;
    text-align: justify;
    font-weight: bolder;
    width: 100%; /* Ensures text fits within the container */
    max-width: 350px;
}

.H1 {
    font-style: italic;
    font-size: 2rem; /* Adjust font size for better visibility on smaller screens */
}

h2 {
    text-decoration: none;
    justify-content: center;
    font-family: consolas;
    text-align: center;
    font-weight: bolder;
    width: 100%;
    max-width: 350px;
}

.link {
    color: var(--link_color);
    text-decoration: none;
}

.link:hover {
    color: var(--link_color);
    text-decoration: none;
}

/* Media Queries for responsive design */
@media (max-width: 768px) {
    .home-anchor {
        margin: 1rem;
        padding: 0.5rem;
    }
    .home-anchor:hover {
        transform: none;
    }

    .c-img {
        width: 25rem;
        height: 15rem;
    }

    .H1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    .description {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .home-anchor {
        margin: 0.5rem;
        padding: 0.5rem;
    }

    .home-anchor:hover {
        transform: none;
    }

    .H1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    .description {
        font-size: 1rem;
    }
}
.disabled-link {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.disabled-link img {
    filter: grayscale(100%);
}
