.header-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(18, 12, 218, 0.4);
    flex-grow: 1;
    position: sticky;
    top: 0;
    z-index: 30;
    backdrop-filter: blur(50px);
    background: var(--body_background);
    box-shadow: 0 0 16px rgba(2, 2, 53, 0.5);
}

.header-logo {
    color: var(--link_color);
    text-decoration: none;
    cursor: pointer;
    font-size: 2.5rem;
    margin: 1rem 2rem;
}

.mode {
    gap: 1rem;
    justify-content: space-between;
    text-align: center;
    display: flex;
    margin-left: auto;
}

.hamburger {
    display: none;
}

.nav-content {
    color: var(--body_color);
    max-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    margin: 0 auto;
}

.nav-content ul {
    display: flex;
    align-items: center;
    margin-left: auto;
    list-style: none;
}
  
.nav-content li {
    margin: 0 1.2rem;
}

.header-link {
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    cursor: pointer;

}

.header-link:hover {
    color: rgb(15, 19, 218);
} 

.login-btn {
    font-size: 20px;
    font-weight: bold;
    background: transparent;
    color: #0004ff;
    padding: 5px 10px;
    border: 3px solid rgb(7, 21, 219);
    border-radius: 4px;
    cursor: pointer;
}

.login-btn:hover {
    color: #ffffff;
    background: transparent;
    box-shadow: 0 0 16px rgb(8, 0, 255);
}

@media screen and (max-width: 768px) {  
    .hamburger {
        display: block;
        cursor: pointer;
        font-size: 2rem;
        margin: 0.5rem 0;
        color: white;
        position: absolute;
        right: 1rem; /* Position to the right end */
    }
  
    .nav-content {
        display: none;
    }
  
    .mode.open .nav-content {
        display: block;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        background-color: black;
        padding: 1rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .nav-content ul {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    
    .nav-content li {
        margin: 0.5rem 0;
    }
    .header-logo {
        font-size: 1.5rem;
        margin: 20px;
    }
}
