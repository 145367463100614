.announcement.container {
    background: transparent;
    animation: fadeInScale 0.8s forwards;
}

.announcement-title {
    margin: 1rem 0;
    padding-bottom: 1rem;
    font-size: 40px;
    text-align: center;
    justify-content: center;
    /* border-bottom: 2px solid #0a0ac3; */
}

.announcement-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 1rem;
}
.left-cont {
    width: 50%;
    margin-left: 2rem;
}
.right-cont {
    width: 25%;
}
.view-btn{
    width: 20%;
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
    margin: 1rem 0;
    color: rgb(255, 255, 255);
    background: transparent;
    border: 2px solid rgb(0, 13, 255);
    border-radius: 4px;
    cursor: pointer;
}

.view-btn:hover {
    background: #0a0ac3;
    border: 2px solid rgb(12, 21, 202);
    transform: scale(1.02);
}
.announce-img {
    width: 400px;
    height: 200px;
}

@media (max-width:  768px) {
    .announcement-list {
        flex-direction: column;
        margin: 0;
    }
    .left-cont {
        width: 100%;
        margin: 10px;
        padding: 10px 0;
    }
    .right-cont {
        text-align: center;
        justify-content: center;
        width: 100%;
        border-bottom: 2px solid #0a0ac3;
        padding-bottom: 2rem;
    }
    .announce-img {
        width: 400px;
        height: 250px;
    }
    
}