
  .ydeep-container {
    background: transparent;
    text-align: center;
    margin-bottom: 10rem;
    padding-top: 5rem;
    animation: fadeInScale 0.8s forwards;
}

.ydeep-cont {
    display: flex;
    flex-wrap: wrap;
    margin: 0 5rem;
}

.ydeep-title {
    font-size: 50px;
    margin: 3rem 10rem;
}

.ydeep-box {
    width: 30%;
    text-align: left;
    background: var(--sub_header);
    /* color: var(--body_background); */
    border: 1px solid #030626;
    border-radius: 10px;
    margin: 0.75rem;
    padding: 0 0.65rem;
    transition: all 0.3s;
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
}

.ydeep-box:hover {
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.9);
    /* transform: scale(1.03); */
}

.ydeep-sub-title {
    height: 70px;
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
    color: rgb(0, 242, 255);
}

.ydeep-desc {
    font-size: 20px;
    text-align: justify;
}

@media (max-width:786px) {
    .ydeep-title {
        margin: 0;
    }
    .ydeep-cont {
        margin: 0 10px;
    }
    .ydeep-box {
        margin: 20px 5px;
        padding: 0 10px;
        width: 100%;
    }
    .ydeep-sub-title {
        height: auto;
    }
}