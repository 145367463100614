:root {
  user-select: none;
  --body_background: #0E0F18;
  --body_color: white;
  --link_color: cyan;
  --sub_header: #130F41;
  --header_color: #F0EAD6;
  --title_color: #424081;
}

body {
  background-color: var(--body_background);
  color: var(--body_color);
}

* {
  font-family: consolas;
  transition: all 0.1s ease-in-out;
}
