@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.solution-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    background: transparent;
    z-index: 1;
    margin-bottom: 10rem;
    padding-top: 5rem;
    animation: fadeInScale 0.8s forwards;

}

.solution-left-side {
    background: transparent;
    width: 100%;
    text-align: center;
    font-family: consolas;
    font-weight: bold;
    padding: 0 10rem;
    z-index: 1;
}

.prod-main-title {
    font-size: 50px;
}

.solution-box {
    display: flex;
    margin: 2rem 1rem;
    border-radius: 10px;
}

.solution-box:hover {
    box-shadow: 0 0 16px rgba(255, 255, 255, 0.9);
}

.solution-box-b {
    border-radius: 10px;
}

.prod-title {
    font-size: 30px;
    margin: 1rem;
    width: fit-content;
    color: #00ddff;
}

.prod-vid {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 500px;
    height: 350px;
}

.solution-description {
    width: 100%;
    background: var(--sub_header);
}

.prod-desc {
    font-size: 20px;
    width: fit-content;
    text-align: justify;
    margin: 1rem;
}

.soln-title {
    font-size: 50px;
}

.soln-cont {
    background: transparent;
    width: 100%;
    text-align: center;
    font-family: consolas;
    font-weight: bold;
    padding: 0 10rem;
    z-index: 1;
}

.soln-cont1 {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
}

.soln-cont12 {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;
}

.soln-left {
    width: 30%;
    margin: 0 5rem;
    text-align: left;
    padding: 0 1rem;
    color:#00ddff;
}

.soln-cont1:hover {
    color:#ffffff;
    background: var(--sub_header);
}

.soln-right {
    width: 50%;
    background: var(--sub_header);
}

.soln-h2 {
    color: rgb(119, 142, 228);
    background: var(--sub_header);
}

.soln-li {
    list-style: none;
    text-align: justify;
    margin: 1.5rem 2rem;
}


@media (max-width:786px) {
    .solution-container, .soln-container {
        flex-direction: column;
    }
    .solution-left-side {
        width: 100%;
        padding: 0;
    }
    .solution-box, .solution-main-box {
        margin: 20px 0;
        flex-direction: column;
    }
    .prod-vid {
        width: 400px;
        height: 300px;
        border-radius: 4px;
    }
    /* .solution-description {
        margin: 0;
        background: rgba(1, 2, 33, 0.6);
        font-family: consolas;
        font-weight: bolder;
        text-align: justify;
        width: 100%;
        height: fit-content;
        position: absolute;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1;
        border-radius: 10px;
    }

    .solution-box:hover .solution-description {
        border-radius: 10px;
        backdrop-filter: blur(5px);
        display: block;
        opacity: 1;
    } */
     .solution-description {
        flex-direction: column;
     }
     .solution-box {
        padding: 1rem;
        box-shadow: 0 0 16px rgba(255, 255, 255, 0.4);
     }
    .soln-cont {
        flex-direction: column;
        margin: 0 1rem;
        padding: 0;
    }
    .soln-left, .soln-right {
        margin: 1rem 0;
        padding: 0;
        width: 90%;
    }
    .soln-h2 {
        font-size: 20px;
    }
}